import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Toast } from "components/Toast";
import { Modal } from "components/Modal";
import { WS_ADMIN, WS_AGENT } from "constants/constants";
import { httpRequestGet } from "utilities/webUtil";
import {
  getUserProfileState,
  setUserProfileState
} from "states/userProfileState";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
class App extends Component {
  componentDidMount() {
    if (!getUserProfileState().token) {
      this.props.history.replace("/login");
    } else {
      httpRequestGet(
        `${getUserProfileState().type === "admin" ? WS_ADMIN : WS_AGENT}/${
          getUserProfileState().id
        }`
      ).then(res => {
        setUserProfileState({ ...getUserProfileState(), ...res.data });
      });
    }
  }

  render() {
    return (
      <React.Suspense fallback={loading}>
        <Toast />
        <Modal />
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={props => <Login {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={props => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={props => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={props => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    );
  }
}

export default withRouter(App);
